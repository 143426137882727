/<template lang="vi">
<div>
    <page-header :title="`Chỉnh sửa người dùng`" :items="breadcrumbs" />
    <div v-if="!loading">
        <b-row>
            <b-col lg="8">
                <b-card>
                    <b-form-group>
                        <label>Họ tên <span class="red">*</span></label>
                        <b-input v-if="form.Verify === 1" v-model="form.Name" readOnly />
                        <b-input v-else v-model="form.Name" placeholder="Nhập họ tên" />
                    </b-form-group>
                    <b-form-group>
                        <label>Số điện thoại/ Email <span class="red">*</span></label>
                        <b-input v-model="form.Id" disabled/>
                    </b-form-group>
                    <b-form-group>
                        <label>Tỉnh/Thành <span class="red">*</span></label>
                        <b-form-select :disabled="this.role_login != ADMIN" @change="change" v-model="form.Province" :options="provinces">
                            <template #first>
                                <b-form-select-option :value="null" disabled>Chọn Tỉnh/Thành</b-form-select-option>
                            </template>
                        </b-form-select>
                    </b-form-group>
                    <b-form-group>
                        <label>Quận/Huyện <span class="red">*</span></label>
                        <b-form-select :disabled="this.role_login!=ADMIN && this.role_login!=PROVINCE_OFFICIAL" v-model="form.District" :options="districts">
                            <template #first>
                                <b-form-select-option :value="null" disabled>Chọn Quận/Huyện</b-form-select-option>
                            </template>
                        </b-form-select>
                    </b-form-group>
                    <b-form-group>
                        <label>Phường/Xã <span class="red">*</span></label>
                        <b-form-select :disabled="this.role_login==WARD_OFFICIAL" v-model="form.Ward" :options="wards">
                            <template #first>
                                <b-form-select-option :value="null" disabled>Chọn Phường/Xã</b-form-select-option>
                            </template>
                        </b-form-select>
                    </b-form-group>
                    <b-form-group>
                        <label>Địa chỉ <span class="font">(Số nhà, đường/thôn, xóm)</span><span class="red">*</span></label>
                        <b-input v-model="form.Address" placeholder="Nhập địa chỉ" />
                    </b-form-group>
                    <b-form-group v-if="form.role_level != 99">
                        <label>Cấp quyền <span class="red">*</span></label>
                        <b-form-select v-model="form.RoleLevel">
                            <b-form-select-option 
                                v-if="role_login==ADMIN" 
                                :value="PROVINCE_OFFICIAL">
                                Cán bộ tỉnh
                            </b-form-select-option>
                            <b-form-select-option 
                                v-if="role_login==ADMIN || 
                                role_login==PROVINCE_OFFICIAL" 
                                :value="DISTRICT_OFFICIAL">
                                Cán bộ huyện
                            </b-form-select-option>
                            <b-form-select-option 
                                v-if="role_login==ADMIN ||
                                role_login==DISTRICT_OFFICIAL" 
                                :value="WARD_OFFICIAL">
                                Cán bộ xã
                            </b-form-select-option>
                            <b-form-select-option 
                                v-if="role_login==ADMIN || 
                                role_login==WARD_OFFICIAL" 
                                :value="PROCESS_OFFICIAL">
                                Cán bộ xử lý
                            </b-form-select-option>
                            <b-form-select-option 
                                v-if="role_login==ADMIN ||
                                role_login==WARD_OFFICIAL" 
                                :value="USER">
                                Người dân
                            </b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                    <b-form-group v-if="form.RoleLevel !== 99">
                        <label>Chức vụ <span class="red">*</span></label>
                        <b-input type="text" v-model="form.Position" placeholder="Nhập chức vụ"></b-input>
                    </b-form-group>
                    <b-form-group>
                        <label>Xác nhận danh tính</label>
                        <b-form-select placeholder="Chờ xác thực danh tính" v-model="form.Verify" :options="[{value: 0, text: 'Chưa xác thực'},{value: 1, text: 'Xác thực'},{value: 2, text: 'Chờ xác thực'}]"></b-form-select>
                    </b-form-group>
                    <b-form-group>
                        <div v-if="showAddErrorMessage" class="alert alert-danger" role="alert">
                            {{addErrorMessage}}
                        </div>
                        <b-button variant="success" class="mr-2" @click.prevent="create">
                            Lưu
                        </b-button>
                        <b-button variant="danger" @click.prevent="toListView">
                            Huỷ
                        </b-button>
                    </b-form-group>
                </b-card>
            </b-col>
            <b-col lg=4>
                <b-card>
                    <b-form-group>
                        <p style="font-weight: 500; font-size: 18px; text-transform: uppercase;">Thông tin danh tính</p>
                        <div v-for="(image, index) in form.Kyc.kyc" :key="index" style="display: inline-block; margin: 0 5px 5px 0">
                            <img :src="parseThumb(image)" @click="openGallery(image)" style="max-height: 200px; width: 300px; cursor: pointer" />
                        </div>
                    </b-form-group>
                    <b-form-group>
                        <label>Họ và tên: </label>
                        <b-input type="text" v-model="form.Kyc.hoTen" />
                    </b-form-group>
                    <b-form-group>
                        <label>Ngày sinh <span class="font">(Ngày/Tháng/Năm)</span>:</label>
                        <b-input v-model="form.Kyc.ngaySinh" placeholder="Ngày sinh" valueType="format" :first-day-of-week="1" lang="vi" format="DD-MM-YYYY"></b-input>
                    </b-form-group>
                    <b-form-group>
                        <label>Giới tính: </label>
                        <b-input type="text" v-model="form.Kyc.gioiTinh"></b-input>
                    </b-form-group>
                    <b-form-group>
                        <label>Số CMND/CCCD: </label>
                        <b-input type="text" v-model="form.Kyc.cccd"></b-input>
                    </b-form-group>
                </b-card>
            </b-col>
        </b-row>
        <LightBox ref="lightbox" :showCaption="false" :showLightBox="false" :media="getListMedia" />
    </div>
</div>
</template>

<script>
import PageHeader from '../../../components/page-header';
import Multiselect from 'vue-multiselect';
import {
    httpClient
} from "../../../_utils/httpClient";
import {
    httpPreview
} from "../../../_utils/httpPreview";
import LightBox from 'vue-it-bigger'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/vi';

import _ from "lodash";
import {
    quillEditor
} from 'vue-quill-editor'
import Swal from 'sweetalert2';
import { checkFile } from '../sentiments/partials/checkTypeFile';
import { ADMIN, PROVINCE_OFFICIAL, DISTRICT_OFFICIAL, WARD_OFFICIAL, PROCESS_OFFICIAL, USER } from '../../../role/checkTypeRole'
import { Base64 } from 'js-base64';
export default {
    name: 'create',
    components: {
        PageHeader,
        Multiselect,
        LightBox,
        DatePicker,
        quillEditor
    },
    data: function () {
        return {
            breadcrumbs: [{
                text: 'Trang chủ',
                to: '/admin/home',
            }, {
                text: 'Người dùng',
                to: '/admin/users',
            }, {
                text: 'Chỉnh sửa',
                to: '#',
                active: true,
            },],
            showAddErrorMessage: false,
            addErrorMessage: '',
            check: 0,
            form: {
                Name: '',
                Id: '',
                Avatar: '',
                Province: null,
                District: null,
                Ward: null,
                Position: null,
                RoleLevel: null,
                Role: '',
                Kyc: {
                    kyc: []
                },
                Address: null,
                Verify: 0
            },
            provinces: [],
            districts: [],
            wards: [],
            loading: false,
            getListMedia: [],
            role_login: null,
            ADMIN,
            PROVINCE_OFFICIAL,
            DISTRICT_OFFICIAL,
            WARD_OFFICIAL,
            PROCESS_OFFICIAL,
            USER,
        };
    },
    watch: {
        'form.Province': {
            handler: function () {
                this.getDistricts(this.form.Province)
                this.getWards(this.form.Province, this.form.District);
            },
            deep: true,
        },
        'form.District': {
            handler: function () {
                if(this.check > 0) {
                    this.form.Ward = null;
                }
                this.check = 1;
                this.getWards(this.form.Province, this.form.District);
            },
            deep: true,
        },
    },
    methods: {
        getRoleLogin() {
            let userData = localStorage.getItem('token').split('.')[1]
            let user = JSON.parse(Base64.decode(userData))
            if (user.role.includes('admin')) {
                this.role_login = 'admin'
            }
            else {
                this.role_login = user.role_level
            }
        },
        checkTypeFile(src) {
            return checkFile(src);
        },
        change() {
            this.form.District = null;
            this.form.Ward = null;
        },
        openGallery(image) {
            let find = image.startsWith(`http`) ? image : httpPreview + image
            console.log('this.getListMedia', this.getListMedia)
            let index = this.getListMedia.findIndex(k => {
                if (k.src === find) return true
            })
            console.log(`index`, index)
            this.$refs.lightbox.showImage(index)
        },
        parseThumb(image) {
            if (image && !image.startsWith(`http`)) {
                return httpPreview + image
            }
            return image
        },
        async toListView() {
            await this.$router.go(-1)
        },
        validator() {
            let mappingErrorName = {
                Name: 'Họ tên',
                Id: 'Số điện thoại / Email',
                Province: 'Tỉnh/Thành',
                District: 'Quận/Huyện',
                Ward: 'Phường/Xã',
                Address: 'Địa chỉ',
                RoleLevel: 'Cấp quyền',
                Position: 'Chức vụ',
            }

            for (let key in mappingErrorName) {
                if (key == 'Address' || key == 'Name') {
                    if (this.form[key].trim() == "") {
                        this.showAddErrorMessage = true;
                        this.addErrorMessage = mappingErrorName[key] + ` không được trống.`;
                        return false;
                    }
                }
                else if (this.form.RoleLevel === 99) {
                    if (this.form[key] == null || this.form[key].length < 1 && key !== 'Position') {
                        this.showAddErrorMessage = true;
                        this.addErrorMessage = mappingErrorName[key] + ' không được trống.'
                        return false;
                    }
                }
                else {
                    if (this.form[key] == null || this.form[key].length < 1) {
                        this.showAddErrorMessage = true;
                        this.addErrorMessage = mappingErrorName[key] + ' không được trống.'
                        return false;
                    }
                }
            }
            return true
        },
        async create() {
            await Swal.queue([{
                title: 'Chỉnh sửa người dùng này',
                confirmButtonText: 'Lưu',
                cancelButtonText: 'Hủy bỏ',
                cancelButtonColor: '#f46a6a',
                confirmButtonColor: '#34c38f',
                showCancelButton: true,
                showconfirmButton: true,
                preConfirm: async () => {
                    this.form.Position = this.form.Position.trim()
                    this.showAddErrorMessage = false
                    if (!this.validator()) return
                    let payload = {
                        ...this.form,
                    }
                    let response = await httpClient.post(`user/update`, payload)
                    if (response.data.code === 0) {
                        if (this.form.RoleLevel !== 99) {
                            Swal.insertQueueStep({
                                title: 'Lưu thành công'
                            }),
                                await this.$router.push({
                                    name: 'admin.users.list'
                                });
                        }
                        else {
                            Swal.insertQueueStep({
                                title: 'Lưu thành công'
                            }),
                                await this.$router.push({
                                    name: 'admin.users.resident.list'
                                });
                        }
                    } else {
                        this.showAddErrorMessage = true
                        this.addErrorMessage = response.data
                    }
                    console.log('response', response)
                    return true;
                }

            },]);
        },
        async getProvince() {
            let response = await httpClient.get(`config/provinces`)
            console.log(response)
            this.provinces = response.data.data
        },
        async getDistricts(province_name) {
            let response = await httpClient.get(`config/districts`, {
                params: {
                    province_name
                }
            })
            this.districts = response.data.data
        },
        async getWards(province_name, district_name) {
            let response = await httpClient.get(`config/wards`, {
                params: {
                    province_name,
                    district_name
                }
            })
            this.wards = response.data.data;
        },
        async getById(id) {
            let response = await httpClient.get(`user`, {
                params: {
                    id
                }
            })
            return response.data.data
        },
    },
    async created() {
        this.getRoleLogin()
        let cloneItem = _.cloneDeep(this.$route.params.form);
        if (cloneItem === undefined) {
            cloneItem = await this.getById(this.$route.params.id)
        }
        this.form = cloneItem;
        if (cloneItem.Kyc === undefined) {
            cloneItem.Kyc = {}
        }
        const setMedia = new Set()
        let media = []
        if (cloneItem.Kyc && cloneItem.Kyc.kyc) {
            cloneItem.Kyc.kyc.forEach(e => {
                setMedia.add(e)
            })
        }
        setMedia.forEach(item => {
            if (item === undefined) return
            if (this.checkTypeFile(item) == 'image') {
                media.push({
                    type: 'image',
                    src: item.startsWith(`http`) ? item : httpPreview + item,
                    thumb: item.startsWith(`http`) ? item : httpPreview + item,
                })
            }
        })
        this.getListMedia = media
        await this.getProvince();
    },
};
</script>

<style>
.red {
    color: red;
}

.text-sm {
    font-weight: 600;
}

.vib-open {
    overflow: hidden;
}

.no-scroll {
    overflow-y: hidden;
}

.vib-hideable {
    transition: opacity .5s ease;
}

.vib-hidden {
    opacity: 0;
}

/* elements styling */

.vib-container {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.85);
    box-sizing: border-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0px;
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 2000;
    -webkit-align-items: center;
    -moz-box-sizing: border-box;
    -webkit-justify-content: center;
    -ms-flex-align: center;
    -webkit-box-align: center;
    -ms-flex-pack: center;
    -webkit-box-pack: center;
}

.vib-content {
    position: relative;
    margin: 0;
    display: block;
}

.vib-image {
    cursor: pointer;
    max-height: calc(100vh);
    cursor: pointer;
    display: block;
    height: auto;
    margin: 0 auto;
    max-width: 100%;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.vib-thumbnail-wrapper {
    background: rgba(0, 0, 0, 0.2);
    padding: 10px 12px;
    text-align: center;
    white-space: nowrap;
    position: fixed;
    display: inline-block;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    top: 0px;
}

.vib-thumbnail {
    background-position: center;
    background-size: cover;
    border-radius: 4px;
    box-shadow: inset 0 0 0 1px hsla(0, 0%, 100%, .2);
    cursor: pointer;
    display: inline-block;
    height: 50px;
    margin: 2px 2px 0 2px;
    overflow: hidden;
    width: 50px;
}

.vib-thumbnail-active {
    background-position: center;
    background-size: cover;
    border-radius: 4px;
    box-shadow: inset 0 0 0 2px white;
    cursor: pointer;
    display: inline-block;
    height: 50px;
    margin: 2px 2px 0 2px;
    overflow: hidden;
    width: 50px;
}

.vib-footer {
    box-sizing: border-box;
    color: white;
    background: rgba(0, 0, 0, 0.2);
    display: flex;
    line-height: 1.3;
    padding: 10px;
    justify-content: space-between;
    text-align: left;
    position: fixed;
    bottom: 0;
    width: 100%;
}

.vib-footer-count {
    flex-shrink: 0;
    padding-left: 1em;
}

.vib-close {
    background: rgba(0, 0, 0, 0.2);
    border: none;
    cursor: pointer;
    outline: none;
    position: fixed;
    top: 0;
    right: 0;
    vertical-align: bottom;
    height: 65px;
    width: 60px;
    padding: 15px 12px 15px 20px;
    border-bottom-left-radius: 50%;
}

.vib-arrow {
    background: rgba(0, 0, 0, 0.2);
    border: none;
    cursor: pointer;
    outline: none;
    padding: 10px;
    position: absolute;
    top: 50%;
    user-select: none;
    height: 65px;
    margin-top: -60px;
    width: 60px;
}

.vib-arrow-left {
    left: 0;
    padding-right: 20px;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
}

.vib-arrow-right {
    right: 0;
    padding-left: 20px;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
}

@media (min-width: 500px) {
    .vib-thumbnail-arrow {
        width: 40px;
    }
}

@media (min-width: 768px) {
    .vib-arrow {
        width: 60px;
    }
}

/* image transitions */

.vib-image-transition-enter-active,
.vib-image-transition-leave-active {
    transition: opacity .2s ease;
}

.vib-image-transition-enter,
.vib-image-transition-leave-to {
    opacity: 0;
}

.vib-image-no-transition-enter-active,
.vib-image-no-transition-leave-active {
    transition: none;
}

.vib-image-no-transition-enter,
.vib-image-no-transition-leave-to {
    opacity: 0;
}

/* container transitions */

.vib-container-transition-enter-active,
.vib-container-transition-leave-active {
    transition: opacity .3s ease;
}

.vib-container-transition-enter,
.vib-container-transition-leave-to {
    opacity: 0;
}

.video-background {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    height: 0;
    width: calc(90vw);
    height: calc(90vh);
}

.video-background iframe {
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 50.25vw;
    max-height: 100vh;
    max-width: 182.78vh;
}
</style><style lang="scss" scoped>
.action-column {
    width: 120px;
    text-align: center;
}

.font {
    font-size: 15px;
    font-weight: 400;
    font-style: italic;
}
</style>
